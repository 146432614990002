export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Език"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Това поле е задължително"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Минимален брой знаци – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Максимален брой знаци – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валиден имейл"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валиден мобилен телефон"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въведете валидна дата"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Започнете регистрацията"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съгласия"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият подпис"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ясно"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, оставете своя подпис"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прочетох, разбрах и съм съгласен с Условията на услугите"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да, съгласен съм"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не, не съм съгласен"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашата резервация"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хотел"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Регистриране"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напускане"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нощи"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гости"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["С натискането на бутона вие се съгласявате <br /> с "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Условия на услугата"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължи"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Виж подробности"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Информация за гости"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължи"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качени документи"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подробности за госта"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканиране на документи"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качете паспортните си снимки или сканирайте паспорта си, за да потвърдите самоличността си. Ние не съхраняваме тези данни."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щракнете, за да въведете подробности за водещия гост"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Щракнете, за да въведете подробности за придружаващ гост"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете държава"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, подгответе своя документ за самоличност и усмихнато лице, за да продължите автоматично проверката на документите и процеса на потвърждение. Това ще отнеме не повече от 1 минута."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качете документ за пътуване"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сканиране на документ за пътуване"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Запазване"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стартиране на процеса на проверка"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Специални заявки"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Искане"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плащане"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Час на пристигане"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ранното пристигане подлежи на потвърждение от имота"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плащайте онлайн сега"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плащане при пристигане"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, изберете как искате да платите за престоя си"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Забележка: Персоналът на хотела ще потвърди възможността за допълнителни услуги при заявка по-късно. Вие не плащате за тях в момента."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашият престой"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължи"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настаняване"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Допълнителни услуги"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Платено"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Общо"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " нощ"]), _normalize([_interpolate(_named("n")), " нощувки"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Подобрете престоя си"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Добавете екстри, за да освежите престоя си преди да пристигнете и ще бъдете приятно изненадани\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Частен трансфер"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, посочете вашето летище, дата на пристигане и номер на полета, за да можем да организираме трансфер за вас"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Летище"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брой гости"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на пристигане"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Номер на полет"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Благодаря!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скъпи"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашето предварително настаняване приключи успешно. Преди пристигане ще получите от нас окончателен имейл за потвърждение на настаняването и инструкции. Благодарим ви и очакваме с нетърпение да ви посрещнем в Hoteza Demo Hotel."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти на хотел"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["щракнете за копиране!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Координатите са копирани!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Плащането е неуспешно"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вашата информация за плащане е неправилна. Моля, опитайте отново или се върнете и изберете плащане при пристигане."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Опитайте отново"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, попълнете вашите данни, за да намерите вашата резервация"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Първо име"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Фамилия"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИД на резервация"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дата на пристигане"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не беше намерена резервация с тази информация. Проверете данните, които сте въвели или се свържете с хотела."])},
      "contactReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Моля, обърнете се към рецепцията"])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay at Reception"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an unused deposit balance. Please visit the reception to receive your remaining deposit refund."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете документ"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изберете кой документ искате да сканирате"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Паспорт"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лична карта"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Резидентска карта"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лице на личната карта"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гръб на личната карта"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Селфи"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Успешно разпознаване на документ и съвпадение на лице. Моля, проверете за точност."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Качване на вашите документи..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка на вашите документи..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройте задната част на вашето идентификационно удостоверение към рамката<br>и направете снимка"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сега сканирайте предната част на вашето идентификационно удостоверение"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Настройте паспорта си към рамката<br>и направете снимка"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поставете лицето си в рамката"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на вашата снимка"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Преглед на вашата снимка. Уверете се, че буквите са ясни<br>и че има добро осветление"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Проверка на вашата снимка..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лицето не съответства"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Камерата не е намерена"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повторно заснемане"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заснемане"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Всичко е готово!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скъпи гост"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Продължи"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " възрастни"]), _normalize([_interpolate(_named("n")), " възрастни"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " дете"]), _normalize([_interpolate(_named("n")), " деца"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Изберете ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Намерете моята резервация"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Изпрати"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Да"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Не"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нещо се обърка.<br>Моля, опитайте отново."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Затвори"])}
  }
}