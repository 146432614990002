export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Språk"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dette feltet er påkrevd"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum antall tegn – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimalt antall tegn – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn en gyldig e-post"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn et gyldig mobilnummer"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skriv inn en gyldig dato"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start innsjekking"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samtykker"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din signatur"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rydd"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennligst legg igjen din signatur"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg har lest, forstått og aksepterer vilkårene for tjenesten"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, jeg godtar"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei, jeg godtar ikke"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din bestilling"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotell"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Innsjekking"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utsjekking"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Natt(er)"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjester"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ved å trykke på knappen, godtar du <br /> vilkårene for"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tjenestevilkår"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsett"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vis detaljer"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjesteinformasjon"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsett"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opplastede dokumenter"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjestedetaljer"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentskanninger"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last opp passbildene dine eller skann passet ditt for å verifisere identiteten din. Vi lagrer ikke disse dataene."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikk for å legge inn detaljer for hovedgjest"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klikk for å legge inn detaljer for medfølgende gjest"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg et land"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennligst forbered ID og smilende ansikt for å fortsette automatisk dokumentkontroll og verifiseringsprosess. Det vil ikke ta mer enn 1 minutt."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last opp reisedokument"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skann reisedokument"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lagre"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start verifiseringsprosess"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spesielle forespørsler"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forespørsel"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankomsttid"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tidligere ankomster er underlagt bekreftelse fra eiendommen"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betal online nå"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betal ved ankomst"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennligst velg hvordan du vil betale for oppholdet ditt"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merk: Hotellets personale vil bekrefte muligheten for tilleggstjenester etter forespørsel senere. Du betaler ikke for disse nå."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ditt opphold"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsett"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overnatting"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekstratjenester"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalt"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totalt"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " natt"]), _normalize([_interpolate(_named("n")), " netter"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forbedre oppholdet ditt"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legg til ekstra tjenester for å lyse opp oppholdet ditt før du ankommer, og du vil bli positivt overrasket\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privat transport"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennligst oppgi flyplass, ankomstdato og flynummer, så kan vi ordne transport for deg"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flyplass"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antall gjester"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankomstdato"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flynummer"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takk!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjære"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din forhåndsinnsjekking er fullført. Før ankomst vil du motta en bekreftelsesmail og instruksjoner fra oss. Takk, og vi ser frem til å ønske deg velkommen til Hoteza Demo Hotel."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotellkontakter"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klikk for å kopiere!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinatene er kopiert!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling feilet"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalingsinformasjonen din er feil. Vennligst prøv igjen eller gå tilbake og velg betaling ved ankomst."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prøv igjen"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennligst fyll ut detaljene dine for å finne bestillingen"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fornavn"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etternavn"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestillings-ID"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ankomstdato"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingen bestilling med denne informasjonen ble funnet. Sjekk dataene du har skrevet inn eller kontakt hotellet."])},
      "contactReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vennligst kontakt resepsjonen"])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start utsjekking"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fullfør utsjekking"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utsjekking"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betal online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betal i resepsjonen"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Takk!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du har en ubrukt depositobalanse. Vennligst besøk resepsjonen for å motta refusjon for ditt gjenværende depositum."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg dokument"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velg hvilket dokument du vil skanne"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pass"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID-kort"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bostedskort"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forside ID-kort"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bakside ID-kort"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentgjenkjenning og ansiktsmatch var vellykket. Vennligst gjennomgå for nøyaktighet."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laster opp dokumentene dine..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validerer dokumentene dine..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juster baksiden av ID-kortet til rammen<br>og ta et bilde"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skann nå forsiden av ID-kortet"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Juster passet ditt til rammen<br>og ta et bilde"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plasser ansiktet ditt i rammen"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomgå selfie-bildet ditt"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomgå bildet ditt. Sørg for at bokstavene er<br>klare og at det har godt lys"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validerer bildet ditt..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansiktet samsvarer ikke"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera ikke funnet"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta på nytt"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ta bilde"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alt er gjort!"])}
    },
    "feedback": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tilbakemelding"])},
      "mostLikeForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva likte du best under oppholdet?"])},
        "services": {
          "bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sengekomfort"])},
          "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratis filmer"])},
          "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkvalitet"])},
          "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobilnøkkel"])},
          "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotellets personale"])}
        }
      },
      "wouldRecommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vil du anbefale hotellet vårt til andre?"])},
      "whatImpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hva var det totale inntrykket av ditt opphold?"])},
      "foodQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matkvalitet"])},
      "staffFriendliness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personalets vennlighet"])},
      "describeExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beskriv din opplevelse av oppholdet på hotellet i egne ord"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Din kommentar"])},
      "ratings": {
        "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utmerket"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bra"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gjennomsnittlig"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dårlig"])}
      }
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kjære gjest"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fortsett"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " voksen"]), _normalize([_interpolate(_named("n")), " voksne"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " barn"]), _normalize([_interpolate(_named("n")), " barn"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Velg en ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finn min bestilling"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nei"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noe gikk galt.<br>Vennligst prøv igjen."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lukk"])}
  }
}