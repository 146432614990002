export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اللغة"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هذا الحقل مطلوب"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["الحد الأدنى لعدد الأحرف - ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["الحد الأقصى لعدد الأحرف - ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل بريدًا إلكترونيًا صالحًا"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل رقم هاتف محمول صالحًا"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أدخل تاريخًا صالحًا"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بدء التسجيل"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الموافقة"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["توقيعك"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ترك توقيعك"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد قرأت شروط الخدمات وفهمتها ووافقت عليها"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم اوافق"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا ، أنا لا أوافق"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الحجز الخاص بك"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فندق"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الوصول"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل المغادرة"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليالي"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الضيوف"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بالضغط على الزر , فإنك توافق <br /> مع"])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شروط الخدمة"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض التفاصيل"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الضيف"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستندات التي تم تحميلها"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل الضيف"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمليات فحص المستندات"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["قم بتحميل صور جواز سفرك أو امسح جواز سفرك للتحقق من هويتك. نحن لا نخزن هذه البيانات."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر لإدخال تفاصيل الضيف الرئيسي"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر لإدخال تفاصيل الضيف المرافق"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر بلدًا"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من فضلك ، قم بتحضير هويتك ووجهك المبتسم للمتابعة تلقائيًا في عملية التحقق من المستندات والتحقق منها. لن يستغرق الأمر أكثر من دقيقة واحدة."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحميل مستند السفر"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح مستند السفر"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حفظ"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابدأ عملية التحقق"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات الخاصة"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وقت الوصول"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوصول المبكر يخضع لتأكيد من العقار"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع عبر الإنترنت الآن"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الدفع عند الوصول"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء اختيار الطريقة التي ترغب في دفع تكاليف إقامتك بها"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشعار: سيؤكد موظفو الفندق إمكانية الخدمات الإضافية عند الطلب لاحقًا. أنت لا تدفع مقابلها في هذا الوقت."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إقامتك"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الإقامة"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات الإضافية"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدفوعة"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لمجموع"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " ليلة"]), _normalize([_interpolate(_named("n")), " ليلة"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عزز إقامتك"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف الإضافات لإضفاء البهجة على إقامتك قبل وصولك وستفاجأ بسرور"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحويل خاص"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى تقديم المطار وتاريخ الوصول ورقم الرحلة حتى نتمكن من ترتيب النقل لك"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of guests"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival date"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight number"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شكرًا لك!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عزيزي"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لقد تم إكمال تسجيل الوصول المسبق بنجاح. قبل وصولك , ستتلقى منا رسالة تأكيد نهائية لتسجيل الوصول وإرشادات. شكرًا لك ونتطلع إلى الترحيب بك في فندق Hoteza Demo."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جهات اتصال الفندق"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["انقر للنسخ!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يتم نسخ الإحداثيات!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فشل الدفع"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الدفع الخاصة بك غير صحيحة. من فضلك , حاول مرة أخرى أو ارجع وحدد الدفع عند الوصول."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاول مرة أخرى"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرجاء ملء التفاصيل الخاصة بك للعثور على الحجز الخاص بك"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاسم الأول"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم العائلة"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معرف الحجز"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ الوصول"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على حجز بهذه المعلومات. تحقق من البيانات التي أدخلتها أو اتصل بالفندق."])},
      "contactReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى التوجه إلى الاستقبال"])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay at Reception"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an unused deposit balance. Please visit the reception to receive your remaining deposit refund."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد المستند"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد المستند الذي تريد مسحه ضوئيًا"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جواز سفر"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطاقة التعريف"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بطاقة اقامة"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهة الأمامية للبطاقة الشخصية"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجهة الخلفية للبطاقة الشخصية"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سيلفي"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الاعتراف بالمستندات وتطابق الوجه ناجح. يرجى مراجعة الدقة"])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جارٍ تحميل مستنداتك..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق من مستنداتك..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاذاة الجانب الخلفي من هويتك مع الإطار<br>والتقاط صورة"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الآن امسح الجانب الأمامي من هويتك"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["محاذاة جواز سفرك مع الإطار<br>والتقاط صورة"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضع وجهك في الإطار"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعة صورتك"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مراجعة صورتك. تأكد من وضوح الحروف<br>وأنها مضاءة بشكل جيد"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحقق من صورتك..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوجه لا يتطابق"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكاميرا غير موجودة"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقاط صورة جديدة"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التقاط"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل شيء تم!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عزيزي الضيف"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متابعة"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " بالغون"]), _normalize([_interpolate(_named("n")), " بالغون"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " أطفال"]), _normalize([_interpolate(_named("n")), " أطفال"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["اختر ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اعثر على الحجز الخاص بي"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نعم"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدث خطأ ما.<br>يرجى المحاولة مرة أخرى."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إغلاق"])}
  }
}