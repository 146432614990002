import { createRouter, createWebHistory } from "vue-router";
import i18n from "@/i18n";

/*const bookingId = "acc1a026-cbb9-4eed-b70f-232958bb7d25";
const bookingHash =
  "NWl0dEFteXJHS1ZYMWRCZ0hsaWIyVnF3TFRldlM2SEJhZ2E3UUhMcENuU05RQzNwMUMvbXFjVnAzeFZCcHN2ag";*/
/*const bookingId = "8766f113-5b79-4c7d-8eb8-aca80b3956c0";
const bookingHash =
  "YlRtVk1xaFN1eG1ZdUhGT0VHemhoTWhjN2RqcEU2ZElKU3F5Znp2OHhDVXVmbnU0OC9yOGNONmlSZWs4cW0rcA";*/
const routes = [
  {
    path: "/",
    redirect: {
      name: "notFoundScreen",
      params: { locale: "en" },
    },
  },
  {
    path: "/:locale?",
    component: {
      template: "<router-view></router-view>",
    },
    redirect: {
      name: "notFoundScreen",
      params: { locale: "en" },
    },
    beforeEnter: (to, from, next) => {
      const locale = to.params?.locale;
      const supportedLocales =
        process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
      if (!supportedLocales.includes(locale)) return next();
      if (i18n.locale !== locale) {
        i18n.locale = locale;
      }
      return next();
    },
    children: [
      {
        path: ":uid/:hash",
        component: () => import("@/views/DefaultScreen"),
        redirect: { name: "welcomeScreen" },
        children: [
          {
            path: "welcome",
            name: "welcomeScreen",
            component: () => import("../views/WelcomeScreen"),
            meta: {
              startPage: true,
            },
          },
          {
            path: "agreements",
            name: "agreementsScreen",
            component: () => import("../views/AgreementsScreen"),
            meta: {
              startPage: true,
            },
          },
          {
            path: "start",
            name: "startScreen",
            component: () => import("../views/StartScreen"),
          },
          {
            path: "upgrade",
            name: "roomUpgradeScreen",
            component: () => import("../views/RoomUpgradeScreen"),
          },
          {
            path: "guest",
            name: "guestScreen",
            component: () => import("../views/GuestScreen"),
          },
          {
            path: "recognition",
            name: "recognitionScreen",
            component: () => import("../views/RecognitionScreen"),
          },
          {
            path: "recognition2",
            name: "recognitionScreen2",
            component: () => import("../views/RecognitionScreen2"),
          },
          {
            path: "mail",
            name: "mailScreen",
            component: () => import("../views/MailScreen"),
          },
          {
            path: "upSale",
            name: "upSaleScreen",
            component: () => import("../views/UpSaleScreen"),
          },
          {
            path: "summary",
            name: "summaryScreen",
            component: () => import("../views/SummaryScreen"),
          },
          {
            path: "thankYou",
            name: "thankYouScreen",
            component: () => import("../views/ThankYouScreen"),
          },
          {
            path: "paymentError",
            name: "paymentErrorScreen",
            component: () => import("../views/PaymentErrorScreen"),
          },
          {
            path: "checkOutStart",
            name: "checkOutStartScreen",
            component: () => import("../views/CheckOutStartScreen"),
          },
          {
            path: "checkOut",
            name: "checkOutScreen",
            component: () => import("../views/CheckOutScreen"),
          },
          {
            path: "bill",
            name: "billScreen",
            component: () => import("../views/BillScreen"),
          },
        ],
      },
      {
        path: "notFound",
        name: "notFoundScreen",
        component: () => import("../views/NotFoundScreen"),
      },
    ],
  },
  {
    path: "/:locale?/find/:uid",
    name: "findBookingScreen",
    component: () => import("../views/FindBooking"),
  },
  {
    path: "/:locale?/feedback",
    name: "FeedbackScreen",
    component: () => import("../views/FeedbackScreen"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const esign = to.query.esign === "true";

  if (esign) {
    switch (to.name) {
      case "welcomeScreen":
        return next({
          name: "agreementsScreen",
          params: to.params,
          query: to.query,
        });
      case "startScreen":
        return next({
          name: "guestScreen",
          params: to.params,
          query: to.query,
        });
      case "recognitionScreen":
        return next({
          name: "agreementsScreen",
          params: to.params,
          query: to.query,
        });
      case "upSaleScreen":
        return next({
          name: "summaryScreen",
          params: to.params,
          query: to.query,
        });
      case "checkOutStartScreen":
        return next({
          name: "checkOutScreen",
          params: to.params,
          query: to.query,
        });
      default:
        break;
    }
  }

  next();
});

export default router;
