export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jezik"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To polje je obvezno"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimalno število znakov – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maksimalno število znakov – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljaven e-poštni naslov"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljavno mobilno številko"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vnesite veljaven datum"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začni prijavo"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soglasja"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaš podpis"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počisti"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosim, pustite svoj podpis"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prebral sem, razumem in se strinjam s Pogoji storitev"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da, strinjam se"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne, ne strinjam se"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša rezervacija"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijava"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odjava"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nočitve"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gosti"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S pritiskom na gumb se strinjate <br /> s "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pogoji storitve"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadaljuj"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poglej podrobnosti"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podatki o gostih"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadaljuj"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naloženi dokumenti"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o gostu"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skeni dokumentov"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naložite fotografije vašega potnega lista ali skenirajte svoj potni list za preverjanje identitete. Teh podatkov ne shranjujemo."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite za vnos podrobnosti za glavnega gosta"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kliknite za vnos podrobnosti za spremljajočega gosta"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite državo"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosimo, pripravite svoj ID in nasmejan obraz za avtomatski postopek preverjanja dokumentov. Trajalo bo manj kot 1 minuto."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naloži potovalni dokument"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skeniraj potovalni dokument"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shrani"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začni postopek preverjanja"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posebne zahteve"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zahteva"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačilo"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas prihoda"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zgodnejši prihodi so odvisni od potrditve objekta"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačajte zdaj na spletu"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačilo ob prihodu"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosimo, izberite način plačila za vaše bivanje"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opomba: Hotelsko osebje bo kasneje potrdilo možnost dodatnih storitev na zahtevo. Trenutno za njih ne plačujete."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše bivanje"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadaljuj"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Namestitev"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodatne storitve"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačano"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skupaj"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " noč"]), _normalize([_interpolate(_named("n")), " noči"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izboljšajte svoje bivanje"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodajte dodatke za polepšanje svojega bivanja pred prihodom in prijetno boste presenečeni\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zasebni prevoz"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosim, navedite letališče, datum prihoda in številko leta, da vam lahko uredimo prevoz"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letališče"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Število gostov"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum prihoda"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Številka leta"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvala!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoštovani"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša predprijava je bila uspešno zaključena. Pred prihodom boste prejeli končno potrditveno sporočilo in navodila. Hvala in veselimo se vašega obiska v Hoteza Demo Hotelu."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakti hotela"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kliknite za kopiranje!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Koordinate so kopirane!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačilo ni uspelo"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaši plačilni podatki so nepravilni. Prosim, poskusite znova ali se vrnite nazaj in izberite plačilo ob prihodu."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskusi znova"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosimo, izpolnite svoje podatke za iskanje rezervacije"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ime"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priimek"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID rezervacije"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum príchodu"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenašla sa žiadna rezervácia s týmito informáciami. Skontrolujte zadané údaje alebo kontaktujte hotel."])},
      "contactReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prosimo, obrnite se na recepcijo"])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Začnite odhod"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokonči odhod"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhod"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačilo preko spleta"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plačilo na recepciji"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hvala vam!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napaka!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imate neporabljen polog. Prosimo, obiščite recepcijo za povračilo neporabljenega pologa."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite dokument"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Izberite, kateri dokument želite skenirati"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potni list"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osebna izkaznica"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dovoljenje za bivanje"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprednja stran osebne izkaznice"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadnja stran osebne izkaznice"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfi"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uspešno prepoznavanje dokumenta in primerjava obraza. Prosimo, preverite natančnost."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nalaganje vaših dokumentov..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preverjanje vaših dokumentov..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poravnajte zadnjo stran vaše osebne izkaznice<br>in posnemite fotografijo"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdaj poskenirajte sprednjo stran vaše osebne izkaznice"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poravnajte svoj potni list z okvirjem<br>in posnemite fotografijo"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postavite svoje obraz v okvir"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preglejte svojo fotografijo"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preglejte svojo fotografijo. Poskrbite, da so črke<br>jasne in da je dobra osvetlitev"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preverjanje vaše fotografije..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obraz se ne ujema"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera ni najdena"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponovno posnemite"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posnemite"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vse je končano!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoštovani gost"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nadaljuj"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " odrasel"]), _normalize([_interpolate(_named("n")), " odrasli"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " otrok"]), _normalize([_interpolate(_named("n")), " otroci"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Izberite ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najdi mojo rezervacijo"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pošlji"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Da"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ne"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prišlo je do napake.<br>Prosim, poskusite ponovno."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zapri"])}
  }
}