export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taal"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit veld is verplicht"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum aantal tekens – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum aantal tekens – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldig e-mailadres in"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldige mobiele telefoon in"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voer een geldige datum in"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Begin met inchecken"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toestemmingen"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw handtekening"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wissen"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laat alstublieft uw handtekening achter"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik heb de Servicevoorwaarden gelezen, begrepen en ga ermee akkoord"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja, ik ga akkoord"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee, ik ben het niet eens"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw Boeking"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hotel"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inchecken"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uitchecken"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachten"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasten"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Door op de knop te drukken, gaat u <br /> akkoord met de "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servicevoorwaarden"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk details"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatie over gasten"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geüploade documenten"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gastgegevens"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenten scannen"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload uw pasfoto's of scan uw paspoort om uw identiteit te verifiëren. Wij slaan deze gegevens niet op."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik om details voor hoofdgast in te voeren"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klik om details in te voeren voor de begeleidende gast"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies een land"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maak alstublieft uw ID en lachende gezicht klaar om automatisch door te gaan met documentcontrole en verificatieproces. Het duurt niet langer dan 1 minuut."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisdocument uploaden"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reisdocument scannen"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["opslaan"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start verificatieproces"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciale verzoeken"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzoek"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankomsttijd"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vroegere aankomsten zijn onder voorbehoud van bevestiging door de accommodatie"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaal nu online"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betalen bij aankomst"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kies alstublieft hoe u uw verblijf wilt betalen"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opmerking: het hotelpersoneel zal de mogelijkheid van aanvullende diensten later op verzoek bevestigen. U betaalt er op dit moment niet voor."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw verblijf"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodatie"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra diensten"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaald"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totaal"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " nacht"]), _normalize([_interpolate(_named("n")), " nachten"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbeter uw verblijf"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg extra's toe om uw verblijf op te fleuren voordat u aankomt en u zult aangenaam verrast zijn\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privéoverdracht"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelieve uw luchthaven, aankomstdatum en vluchtnummer door te geven zodat we een transfer voor u kunnen regelen"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luchthaven"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aantal gasten"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankomstdatum"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vluchtnummer"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bedankt!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beste"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw pre-check-in is succesvol afgerond. Voor aankomst ontvangt u van ons een definitieve check-in bevestigingsmail en instructies. Dank u en we kijken ernaar uit u te verwelkomen in Hoteza Demo Hotel."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotelcontacten"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klik om te kopiëren!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De coördinaten zijn gekopieerd!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Betaling mislukt"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw betalingsgegevens zijn onjuist. Probeer het opnieuw of ga terug en selecteer betaling bij aankomst."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Probeer het opnieuw"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vul uw gegevens in om uw boeking te vinden"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voornaam"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achternaam"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boekings-ID"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aankomstdatum"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is geen boeking met deze informatie gevonden. Controleer de door u ingevulde gegevens of neem contact op met het hotel."])},
      "contactReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neem alstublieft contact op met de receptie"])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay at Reception"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an unused deposit balance. Please visit the reception to receive your remaining deposit refund."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer document"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selecteer welk document u wilt scannen"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paspoort"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID-kaart"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewonerskaart"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voorkant van identiteitskaart"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achterkant van identiteitskaart"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documentherkenning en gezichtsherkenning succesvol. Controleer alstublieft voor nauwkeurigheid."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw documenten worden geüpload..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het controleren van uw documenten..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richt de achterkant van uw identiteitskaart op het kader<br>en maak een foto"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan nu de voorkant van uw identiteitskaart"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richt uw paspoort op het kader<br>en maak een foto"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plaats uw gezicht in het kader"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk uw foto"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bekijk uw foto. Zorg ervoor dat de letters<br>duidelijk zijn en dat er goed licht is"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uw foto wordt gevalideerd..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gezicht komt niet overeen"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera niet gevonden"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opnieuw nemen"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto maken"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alles is gereed!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beste gast"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doorgaan"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " volwassene"]), _normalize([_interpolate(_named("n")), " volwassenen"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " kind"]), _normalize([_interpolate(_named("n")), " kinderen"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Kies een ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vind mijn boeking"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verzenden"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nee"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er is iets misgegaan.<br>Probeer het opnieuw."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sluiten"])}
  }
}