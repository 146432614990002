import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { maska } from "maska";

import axios from "@/axios";
import i18n from "./i18n";

const app = createApp(App).use(i18n);
app.directive("maska", maska);
app.config.globalProperties.$axios = axios;
app.use(store).use(router).mount("#app");

document.addEventListener("click", (event) => {
  const payload = {
    type: "esign",
    message: "User interacted",
    timestamp: Date.now(),
    source: {
      url: window.location.href,
      element: event.target.tagName,
      details: {
        x: event.clientX,
        y: event.clientY,
      },
    },
  };
  window.parent.postMessage(payload, "*");
});
