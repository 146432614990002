export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nyelv"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ez a mező kötelező"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimális karakterszám – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximális karakterszám – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvényes e-mail-címet adjon meg"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érvényes mobiltelefont adjon meg"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adja meg az érvényes dátumot"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés indítása"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hozzájárulás"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön aláírása"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Törlés"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, hagyja aláírását"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elolvastam, megértettem és elfogadom a Szolgáltatási feltételeket"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen, egyetértek"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem, nem értek egyet"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön foglalása"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bejelentkezés"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kijelentkezés"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éjszakák"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendégek"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A gomb megnyomásával egyetért <br /> a "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szolgáltatási feltételek"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Részletek megtekintése"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendéginformációk"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feltöltött dokumentumok"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendégadatok"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentumok szkennelése"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltse fel útlevélképeit, vagy szkennelje be az útlevelét személyazonosságának igazolásához. Ezeket az adatokat nem tároljuk."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson a vezető vendég adatainak megadásához"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kattintson a kísérő vendég adatainak megadásához"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válasszon országot"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, készítse elő személyi igazolványát és mosolygó arcát az automatikus dokumentum-ellenőrzési és -ellenőrzési folyamat folytatásához. Ez nem tart tovább 1 percnél."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úti okmány feltöltése"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úti okmány beolvasása"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentés"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrzési folyamat indítása"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speciális kérések"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érkezési idő"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Korábbi érkezés csak a szálláshely visszaigazolásával lehetséges"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizessen online most"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetés érkezéskor"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, válassza ki, hogyan szeretne fizetni a tartózkodásért"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megjegyzés: A szálloda személyzete kérésre később visszaigazolja a további szolgáltatások lehetőségét. Ön jelenleg nem fizet értük."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az Ön tartózkodása"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szállás"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra szolgáltatások"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetett"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Összesen"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " éjszaka"]), _normalize([_interpolate(_named("n")), " éjszaka"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fokozza tartózkodását"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adjon hozzá extrákat, hogy feldobja tartózkodását, mielőtt megérkezik, és kellemes meglepetésben lesz része\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privát átutalás"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adja meg repülőterét, érkezési dátumát és járatszámát, hogy meg tudjuk szervezni a transzfert"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repülőtér"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendégek száma"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érkezés dátuma"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight number"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Köszönöm!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kedves"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az előzetes bejelentkezés sikeresen megtörtént. Érkezés előtt kap tőlünk egy végső bejelentkezést megerősítő e-mailt és utasításokat. Köszönjük, és szeretettel várjuk a Hoteza Demo Hotelben."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Contacts"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kattintson a másoláshoz!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A koordináták át vannak másolva!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A fizetés sikertelen"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fizetési információi helytelenek. Kérjük, próbálja újra, vagy menjen vissza, és válassza ki a fizetést érkezéskor."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, adja meg adatait a foglalás megtalálásához"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keresztnév"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vezetéknév"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foglalási azonosító"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Érkezési dátum"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ezzel az információval nem találtunk foglalást. Ellenőrizze a megadott adatokat, vagy lépjen kapcsolatba a szállodával."])},
      "contactReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kérjük, forduljon a recepcióhoz"])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay at Reception"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an unused deposit balance. Please visit the reception to receive your remaining deposit refund."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a dokumentumot"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Válassza ki a beolvasni kívánt dokumentumot"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Útlevél"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyi igazolvány"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lakossági kártya"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyi igazolvány előlapja"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Személyi igazolvány hátoldala"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szelfi"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentumfelismerés és arckép-egyeztetés sikeres. Kérjük, ellenőrizze a pontosságot."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentumaid feltöltése..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumentumainak ellenőrzése..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illeszd be az igazolványod hátsó részét a keretbe<br>és készíts egy fényképet"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most pásztázd be az igazolványod előlapját"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illeszd be az útlevélét a keretbe<br>és készíts egy fényképet"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Helyezd az arcodat a keretbe"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrizd a fényképed"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ellenőrizd a fényképed. Győződj meg róla, hogy a betűk<br>tiszták és jó a megvilágítás"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fényképed érvényesítése..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az arc nem egyezik"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kamera nem található"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Újrafelvétel"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fényképezés"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minden kész!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kedves Vendég"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Folytatás"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " felnőtt"]), _normalize([_interpolate(_named("n")), " felnőtt"])])},
    "childs": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " gyermek"]), _normalize([_interpolate(_named("n")), " gyermek"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Válasszon egy ", _interpolate(_named("item")), " elemet"])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foglalásam keresése"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Küldés"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Igen"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nem"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valami hiba történt.<br>Kérlek, próbáld újra."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezárás"])}
  }
}