export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo campo è obbligatorio"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Numero minimo di caratteri – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Numero massimo di caratteri – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un indirizzo email valido"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un cellulare valido"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci una data valida"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia il check-in"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consensi"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua firma"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancella"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lascia la tua firma"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho letto, compreso e accetto i Termini di servizio"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì, sono d'accordo"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, non sono d'accordo"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La tua prenotazione"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Albergo"])},
      "check-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notti"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ospiti"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premendo il pulsante, accetti <br /> con il "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termini di servizio"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continua"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualizza dettagli"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informazioni per gli ospiti"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continua"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documenti caricati"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dettagli dell'ospite"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scansioni di documenti"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica le foto del tuo passaporto o scansiona il tuo passaporto per verificare la tua identità. Non memorizziamo questi dati."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fai clic per inserire i dettagli per l'ospite principale"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clicca per inserire i dettagli per l'accompagnatore"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli un paese"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, prepara il tuo documento d'identità e la faccia sorridente per procedere automaticamente al controllo dei documenti e al processo di verifica. Non ci vorrà più di 1 minuto."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carica documento di viaggio"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scansiona documento di viaggio"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salva"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avvia il processo di verifica"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richieste Speciali"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Richiesta"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orario di arrivo"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gli arrivi anticipati sono soggetti a conferma da parte della struttura"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga online ora"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paga all'arrivo"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scegli come desideri pagare il tuo soggiorno"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avviso: lo staff dell'hotel confermerà la possibilità di servizi aggiuntivi su richiesta in un secondo momento. Al momento non li paghi."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo soggiorno"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continua"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alloggio"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Servizi extra"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagato"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Totale"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " notti"]), _normalize([_interpolate(_named("n")), " notti"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Migliora il tuo soggiorno"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi extra per rallegrare il tuo soggiorno prima del tuo arrivo e rimarrai piacevolmente sorpreso\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trasferimento privato"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per favore, fornisci il tuo aeroporto, la data di arrivo e il numero del volo in modo che possiamo organizzare un trasferimento per te"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aeroporto"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero di ospiti"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di arrivo"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numero del volo"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grazie!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caro"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo pre check-in è stato completato con successo. Prima dell'arrivo riceverai da noi un'e-mail di conferma finale del check-in e le istruzioni. Grazie e non vediamo l'ora di darti il benvenuto all'Hoteza Demo Hotel."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contatti Hotel"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fai clic per copiare!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le coordinate sono copiate!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pagamento non riuscito"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I tuoi dati di pagamento non sono corretti. Riprova o torna indietro e seleziona il pagamento all'arrivo."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riprova"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci i tuoi dati per trovare la tua prenotazione"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID prenotazione"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di arrivo"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non è stata trovata alcuna prenotazione con queste informazioni. Controlla i dati inseriti o contatta l'hotel."])},
      "contactReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si prega di rivolgersi alla reception"])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay at Reception"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an unused deposit balance. Please visit the reception to receive your remaining deposit refund."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona documento"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seleziona il documento che desideri scansionare"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passaporto"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta d'identità"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carta di residenza"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fronte della carta d'identità"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retro della carta d'identità"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Riconoscimento documento e corrispondenza facciale riusciti. Per favore, controlla per precisione."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caricamento dei tuoi documenti..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validazione dei tuoi documenti..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allinea il retro del tuo documento d'identità alla cornice<br>e scatta una foto"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ora scansiona il lato anteriore del tuo documento d'identità"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allinea il tuo passaporto alla cornice<br>e scatta una foto"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posiziona il tuo volto nella cornice"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi la tua foto"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rivedi la tua foto. Assicurati che le lettere siano<br>chiare e che ci sia una buona illuminazione"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validazione della tua foto..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il volto non corrisponde"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telecamera non trovata"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rifare"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cattura"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tutto è fatto!"])}
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caro ospite"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["continua"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " adulti"]), _normalize([_interpolate(_named("n")), " adulti"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " bambini"]), _normalize([_interpolate(_named("n")), " bambini"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Scegli un ", _interpolate(_named("elemento"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trova la mia prenotazione"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invia"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sì"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualcosa è andato storto.<br>Per favore, riprova."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiudi"])}
  }
}