export default {
  "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
  "validations": {
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is required"])},
    "minLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Minimum number of characters – ", _interpolate(_named("n"))])},
    "maxLength": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Maximum number of characters – ", _interpolate(_named("n"))])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid email"])},
    "mustBeValidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid mobile phone"])},
    "mustBeDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter valid date"])}
  },
  "screens": {
    "welcome": {
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start check-in"])}
    },
    "agreements": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consents"])},
      "yourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Signature"])},
      "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clear"])},
      "pleaseLeaveYourSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please leave your signature"])},
      "read": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have read, understood and agree to the Terms of Services"])},
      "agree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, I agree"])},
      "disagree": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, I disagree"])}
    },
    "overview": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Booking"])},
      "hotel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
      "checkin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-in"])},
      "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "nights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nights"])},
      "guests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests"])},
      "press": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By pressing the button, you agree <br /> with the "])},
      "terms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms of Service"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "roomDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["View details"])}
    },
    "guest": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guests Information"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "uploaded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploaded documents"])},
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guest Details"])},
      "scans": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents Scans"])},
      "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload your passport photos or scan your passport to verify your identity. We do not store this data."])},
      "guestDetailsLead": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to enter details for lead guest"])},
      "guestDetailsAccompanying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Click to enter details for accompanying guest"])},
      "selectCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a country"])},
      "verificationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, prepare your ID and smiling face to proceed automatically document check and verification process. It will take not more than 1  minute."])},
      "buttons": {
        "upload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload travel document"])},
        "scan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scan travel document"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
        "startVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start verification process"])}
      }
    },
    "summary": {
      "specialRequests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Requests"])},
      "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Request"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment"])},
      "arrivalTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival time"])},
      "earlierCheckin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Earlier arrivals are subject to confirmation from the property"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay online now"])},
      "payOffline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay on arrival"])},
      "paymentNotify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please choose how you would like to pay for your stay"])},
      "specialNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notice: The hotel staff will confirm the possibility of additional services upon request later. You do not pay for them at this time."])}
    },
    "preCheckout": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Stay"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
      "accommodation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accommodation"])},
      "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Services"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paid"])},
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "nights": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " night"]), _normalize([_interpolate(_named("n")), " nights"])])}
    },
    "upsale": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enhance Your Stay"])},
      "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add extras to brighten up your stay before you arrive and you'll be pleasantly surprised\n"])},
      "transfer": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Transfer"])},
        "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please provide your airport, arrival date and flight number so we can arrange a transfer for you"])},
        "airport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Airport"])},
        "numberOfGuests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of guests"])},
        "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival date"])},
        "flightNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Flight number"])}
      }
    },
    "thankYou": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank You!"])},
      "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You pre check-in has been completed successfully. Before arrival you will receive from us a final check-in confirmation mail and instructions. Thank you and we look forward to welcoming you at Hoteza Demo Hotel."])},
      "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel Contacts"])},
      "clickToCopy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["click to copy!"])},
      "copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The coordinates are copied!"])}
    },
    "paymentError": {
      "paymentFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment failed"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your payment information is incorrect. Please, try again or go back and select payment on arrival."])},
      "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try Again"])}
    },
    "findBooking": {
      "fillDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please fill your details to find your booking"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First name"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last name"])},
      "bookingId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Booking ID"])},
      "arrivalDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arrival date"])},
      "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No booking with this information was found. Check the data you entered or contact the hotel."])},
      "contactReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact the reception"])}
    },
    "checkOut": {
      "start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start check-out"])},
      "finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finish check-out"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check-out"])},
      "payOnline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay Online"])},
      "payAtReception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pay at Reception"])},
      "thankYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thank you!"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oops!"])},
      "unusedDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You have an unused deposit balance. Please visit the reception to receive your remaining deposit refund."])}
    },
    "recognition": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select document"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select which document you want to scan"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
      "idCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID Card"])},
      "residentCard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resident Card"])},
      "frontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Front ID Card"])},
      "backId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back ID Card"])},
      "selfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selfie"])},
      "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document recognition and face match successful. Please, review for accuracy."])},
      "uploadingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uploading your documents..."])},
      "validatingDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validating your documents..."])},
      "captureBackId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Align back side of your ID to the frame<br>and take a photo"])},
      "captureFrontId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Now scan the front side of your ID"])},
      "capturePassport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Align your passport to the frame<br>and take a photo"])},
      "captureFace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Place your face in the frame"])},
      "reviewSelfie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review your photo"])},
      "reviewPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review your photo. Make sure the letters are<br>clear and it has good lighting"])},
      "validatingPhoto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validating your photo..."])},
      "faceMatchFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Face doesn't match"])},
      "cameraNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Camera not found"])},
      "retake": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retake"])},
      "capture": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capture"])},
      "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All is done!"])}
    },
    "feedback": {
      "headerTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Feedback"])},
      "mostLikeForm": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What did you like most during your stay?"])},
        "services": {
          "bed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bed comfort"])},
          "movies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Complimentary movies"])},
          "food": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food quality"])},
          "mobile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile key"])},
          "staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel staff"])}
        }
      },
      "wouldRecommend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you recommend our hotel to other people?"])},
      "whatImpression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What was the overall impression of your stay?"])},
      "foodQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Food quality"])},
      "staffFriendliness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel staff friendliness"])},
      "describeExperience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Describe your experience of staying at our hotel in your own words"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your comment"])},
      "ratings": {
        "excellent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Excellent"])},
        "good": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Good"])},
        "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Average"])},
        "poor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poor"])}
      }
    }
  },
  "common": {
    "dear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dear Guest"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "adults": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " adult"]), _normalize([_interpolate(_named("n")), " adults"])])},
    "children": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize([_interpolate(_named("n")), " child"]), _normalize([_interpolate(_named("n")), " children"])])},
    "selectDefaultItem": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Choose a ", _interpolate(_named("item"))])},
    "findBooking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Find my booking"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "tryAgain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong.<br>Please try again."])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])}
  }
}